import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Lalajicontent from "../components/subpages/lalajicontent";

import Commonbreadcreumb from "../components/subpages/commonbreadcumb";

// markup
const lalajiPage = () => {
  return (
    <div>
      <Seo title="lalaji" />

      <Layout isSticky>
        <section>
          <Commonbreadcreumb />
        </section>

        <section>
          <Lalajicontent />
        </section>
      </Layout>
    </div>
  );
};

export default lalajiPage;
