import React from "react";

export default function Lalajicontent() {
  return (
    <section className="lalajicontentpage pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Lalaji Maharaj</h4>
            <p className="theme-p-tag"> 1873 A.D. – 1931 A.D. </p>
            <p className="theme-p-tag">
              {" "}
              Shri Ram Chandra of Fatehgarh, affectionately known as Lalaji, was
              the first guru in the Heartfulness tradition. He revived the
              ancient art of yogic transmission so that all seekers with sincere
              interest in their evolution could experience the highest expansion
              of consciousness.
            </p>
            <p className="theme-p-tag">
              {" "}
              It was Lalaji’s conviction that family life was most conducive to
              self-development. In fact, he credited his wife with inspiring him
              and keeping him alert on the path of truth.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              By nature Lalaji was always calm, but easily moved by the pains
              and pleasures of others. Rarely was he angered. Not given to
              superfluous talk, he was adept at using language to communicate
              his thoughts and captivate the hearts of his listeners. His
              personality, mode of living and general behaviour earned Lalaji
              the affection and respect of everyone.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Lalaji could bring about the spiritual awakening in a person with
              a blink of his eyelids, but displays of any kind were not liked by
              him. For removing various complexities of the heart, he asked his
              followers to make friends of their enemies. He regarded morality
              as essential to Self-Realization and love as the greatest
              practice. He taught that afflictions are divine blessings holding
              many secrets.
            </p>
            <p className="theme-p-tag">
              {" "}
              The art of transmission was passed from Lalaji to Shri Ram Chandra
              of Shahjahanpur (known as Babuji), who became the second guide in
              the Heartfulness tradition.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
